import { trackTokenSearchGA, trackTokenSelectGA } from '@/app/analytics'
import { CoinButton, Conditional, For, TokenImage } from '@/app/components'
import { Modal } from '@/app/components/Modal'
import { clsxm } from '@/app/helpers/clsxm'
import { useTokenLiquidity, useTokensQuery, useTopCoinsQuery } from '@/app/hooks/queries'
import { usePreferencesStore } from '@/app/stores'
import { IconJupiter, IconLavarage, IconLavarageLogo, IconSearch, IconX } from '@/assets/svgs'
import { type TokenInfo } from '@/services'
import EmojiPicker, { Theme } from 'emoji-picker-react'
import Fuse from 'fuse.js'
import { debounce } from 'lodash'
import React, { type CSSProperties, type ChangeEvent, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { FixedSizeList as List } from 'react-window'
import { useShallow } from 'zustand/react/shallow'
import { SOL_ADDRESS, WIF_ADDRESS } from '../../../../config.js'
import { NEW_COIN_ADDRESSES_ARRAY } from '../../../app.config.js'
import { checkWhitelisted } from '../utils/checkWhitlisted.js'

enum TokenListFilters {
  ALL = 'all',
  MARGIN = 'margin',
}

interface TokenListProps {
  listMode: 'default' | 'long' | 'baseSwap' | 'quoteSwap' | 'short'
  // handleTabChange?: (tab: TradeModeType) => void
}

export const TokenList = memo(({ listMode }: TokenListProps) => {
  const isSwapList = listMode === 'baseSwap' || listMode === 'quoteSwap'
  const [inputValue, setInputValue] = useState<string>('')
  const [debouncedInputValue, setDebouncedInputValue] = useState<string>('')
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false)
  const [hasInteracted, setHasInteracted] = useState<boolean>(false)
  const [tokensFilter, setTokensFilter] = useState<TokenListFilters>(TokenListFilters.MARGIN)
  const [baseTokenLong, setBaseTokenLong, baseTokenSwap, setBaseTokenSwap, quoteTokenLong, setQuoteTokenLong, quoteTokenSwap, setQuoteTokenSwap] = usePreferencesStore(useShallow(s => [s.baseTokenLong, s.setBaseTokenLong, s.baseTokenSwap, s.setBaseTokenSwap, s.quoteTokenLong, s.setQuoteTokenLong, s.quoteTokenSwap, s.setQuoteTokenSwap]))
  const baseToken = listMode === 'baseSwap' ? baseTokenSwap : listMode === 'quoteSwap' ? quoteTokenSwap : baseTokenLong
  const setBaseToken = listMode === 'baseSwap' ? setBaseTokenSwap : listMode === 'quoteSwap' ? setQuoteTokenSwap : setBaseTokenLong
  const quoteToken = listMode === 'baseSwap' ? quoteTokenSwap : listMode === 'quoteSwap' ? baseTokenSwap : quoteTokenLong
  // const setQuoteToken = listMode === 'quoteSwap' ? setQuoteTokenSwap : setQuoteTokenLong
  const { tokens = [], refetch: fetchTokens, isLoading: areTokensLoading } = useTokensQuery('all')

  const [isBaseTokenUpdated, setIsBaseTokenUpdated] = useState<boolean>(false)

  useEffect(() => {
    if (isBaseTokenUpdated) return
    if (tokens.length === 0) return
    const updatedToken = tokens.find(t => t.address === baseToken.address)
    if (updatedToken) {
      setBaseToken(updatedToken)
    }
    else {
      const tokenWIF = tokens.find(t => t.address === WIF_ADDRESS)
      if (tokenWIF) {
        setBaseToken(tokenWIF)
      }
      else {
        console.error('Token not found', tokens)
      }
    }
    setIsBaseTokenUpdated(true)
  }, [tokens, setBaseToken])

  const { refetchWithToken: updateTokenLiquidity } = useTokenLiquidity()
  const wrapperRef = useRef<HTMLDivElement>(null)
  const listRef = useRef<List>(null)
  const [isDebouncing, setIsDebouncing] = useState<boolean>(false)
  const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState<boolean>(false)

  const whitelistedForQuote = [...tokens]
    .filter(t => t.whitelistedForAddress?.includes(quoteToken.address))
    .sort((a, b) => {
      if (a.isNewCoin && !b.isNewCoin) {
        return -1
      }
      else if (!a.isNewCoin && b.isNewCoin) {
        return 1
      }
      else {
        return (b.daily_volume || 0) - (a.daily_volume || 0)
      }
    })

  const { topCoins = [] } = useTopCoinsQuery(tokens)
  // const topCoinsForISC = whitelistedForQuote.slice(0, 10)
  const topCoinFilteredByQuote = quoteToken.symbol === 'SOL' ? topCoins : topCoins.filter(t => t.whitelistedForAddress?.includes(quoteToken.address))

  const allTokensForQuote = [...tokens]
    .filter(t => t.address !== quoteToken.address)

  // const allTokens = useMemo(() => {
  //   if (tokensFilter === TokenListFilters.MARGIN) {
  //     if (quoteToken?.symbol === 'SOL') {
  //       return tokensForQuote.whitelistedForSOLTokens
  //     }
  //     if (quoteToken?.symbol === 'USDC') {
  //       return tokensForQuote.whitelistedForNotSOL
  //     }
  //   }
  //   if (tokensFilter === TokenListFilters.ALL) {
  //     if (quoteToken?.symbol === 'SOL') {
  //       return tokensForQuote.allTokensForSOL
  //     }
  //     if (quoteToken?.symbol === 'USDC') {
  //       return tokensForQuote.allTokensForNotSOL
  //     }
  //     return []
  //   }
  //   return []
  // }, [tokensFilter, quoteToken, tokensForQuote])

  // const allTokens = useMemo(() => [...tokens].sort((a, b) => (b.daily_volume || 0) - (a.daily_volume || 0)).slice(0, 1000), [tokens])
  const scrollModalToTop = () => {
    if (listRef.current) {
      listRef.current.scrollTo(0)
    }
  }

  const fuse = useMemo(() => {
    const options = {
      keys: ['name', 'symbol'],
      threshold: 0.3,
    }
    return new Fuse(tokens, options)
  }, [tokens])

  useEffect(() => {
    const preventTouchMove = (e: TouchEvent) => {
      if (wrapperRef.current && e.target instanceof Node && wrapperRef.current.contains(e.target)) {
        return
      }
      e.preventDefault()
    }

    if (isModalVisible) {
      document.addEventListener('touchmove', preventTouchMove, {
        passive: false,
      })
      document.body.style.overflow = 'hidden'
    }
    else {
      document.removeEventListener('touchmove', preventTouchMove)
      document.body.style.overflow = ''
    }
    return () => {
      document.removeEventListener('touchmove', preventTouchMove)
      document.body.style.overflow = ''
    }
  }, [isModalVisible])

  useEffect(() => {
    fetchTokens()
  }, [fetchTokens])

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedInputValue(inputValue)
      setHasInteracted(true)
      setIsDebouncing(false)
      scrollModalToTop()
    }, 1000)

    setIsDebouncing(true)
    handler()

    return () => {
      handler.cancel()
      setIsDebouncing(false)
    }
  }, [inputValue])

  const filteredTokens: TokenInfo[] = useMemo(() => {
    if (!hasInteracted || debouncedInputValue === '') {
      if (listMode === 'default') {
        if (tokensFilter === TokenListFilters.MARGIN) {
          return whitelistedForQuote
        }
        else {
          return allTokensForQuote
        }
      }
      else if (isSwapList) {
        return allTokensForQuote
      }
      else {
        return whitelistedForQuote
      }
    }

    // If input is exactly 44 characters (a Solana address), skip Fuse search
    if (debouncedInputValue.length === 44) {
      const exactMatch = tokens.find(token => token.address.toLowerCase() === debouncedInputValue.toLowerCase())
      return exactMatch ? [exactMatch] : []
    }

    const fuseSearchResult = fuse.search(debouncedInputValue)

    const exactMatches = tokens.filter(token => token.address === debouncedInputValue)
    const fuseMatches = fuseSearchResult
      .filter(({ item }) => item.address !== debouncedInputValue)
      .map(({ item }) => item)

    const out = [...exactMatches, ...fuseMatches]

    if (tokensFilter === TokenListFilters.ALL) {
      return out
    }
    else {
      return out.filter((t: TokenInfo) => t.whitelistedForAddress?.includes(quoteToken.address))
    }
  }, [tokens, tokensFilter, debouncedInputValue, hasInteracted, fuse, allTokensForQuote])

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleTokenClick = useCallback(
    (selectedToken: TokenInfo) => {
      trackTokenSelectGA(selectedToken.symbol)
      setInputValue('')
      updateTokenLiquidity(selectedToken)
      // console.log('selectedToken', selectedToken)
      // TODO: investigate why this is needed
      // selectedToken.whitelisted = true
      setBaseToken(selectedToken)
      setIsModalVisible(false)
      setHasInteracted(false)
    },
    [setBaseToken, updateTokenLiquidity],
  )
  const navigate = useNavigate()
  const location = useLocation()
  const handleLavaClick = (selectedToken: TokenInfo) => {
    trackTokenSelectGA(selectedToken.symbol)
    setInputValue('')
    updateTokenLiquidity(selectedToken)
    setBaseTokenLong(selectedToken)
    setQuoteTokenLong(tokens.find(t => t.address === SOL_ADDRESS)!)
    setHasInteracted(false)
    navigate({ pathname: '/long', search: location.search })
    setIsModalVisible(false)
  }

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (isModalVisible && wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        setInputValue('')
        setIsModalVisible(false)
        setHasInteracted(false)
      }
    },
    [isModalVisible],
  )

  const toggleModal = () => {
    setInputValue('')
    setIsModalVisible(prev => !prev)
  }

  // useEffect(() => {
  //   if (isModalVisible) {
  //     setHasInteracted(false)
  //     fetchTokens()
  //   }
  // }, [isModalVisible, fetchTokens])

  useEffect(() => {
    if (isModalVisible) {
      document.addEventListener('mousedown', handleClickOutside as EventListener, true)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside as EventListener, true)
    }
  }, [isModalVisible, handleClickOutside])

  // useEffect(() => {
  //   if (!tokens.length || (list === 'base' && baseToken) || (list === 'quote' && quoteToken)) return

  // WIF
  // const defaultToken = (list === 'quote') ? tokens.find(t => t.address === 'So11111111111111111111111111111111111111112') : tokens.find(t => t.address === 'EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v')

  // const relatedPool = getRelatedPool(defaultToken, pools, quoteToken?.address)
  // if (list === 'base') {
  //   setBaseToken({
  //     ...defaultToken,
  //     availableLiquidity: relatedPool?.nodeWallet.totalFunds?.toNumber() || 0,
  //     dailyInterest: (relatedPool?.interestRate || 0) / 365,
  //   })
  // }
  // else {
  //   setQuoteToken({
  //     ...defaultToken,
  //     availableLiquidity: (relatedPool?.nodeWallet.totalFunds || BigNumber('0'))?.toNumber() || 0,
  //     dailyInterest: (relatedPool?.interestRate || 0) / 365,
  //   })
  // }
  // }, [tokens.length, list === 'base' ? baseToken : quoteToken, list === 'base' ? setBaseToken : setQuoteToken, pools, updateTokenLiquidity, fetchTokens, tokens])

  const prettyAddress = (publicKey: string) => {
    return `${publicKey.slice(0, 4)}..${publicKey.slice(-3)}`
  }

  const quantityOfMarginTokens = whitelistedForQuote.length

  const Row = memo(({ index, style }: { index: number; style: CSSProperties }) => {
    const token = filteredTokens[index]
    return (
      <div key={`token${index}`} className='px-[10px] last:pb-[64px]' style={style}>
        <div
          className={clsxm(
            'desk:hover:bg-opacity-20 mt-[10px] flex h-[44px] w-full cursor-pointer items-center justify-between rounded-lg bg-opacity-10 pt-[-10px] text-base transition',
            {
              'bg-alt text-alt': checkWhitelisted(token, quoteToken.address),
              'bg-main text-main': !checkWhitelisted(token, quoteToken.address),
            },
          )}
          style={{ cursor: 'pointer' }}
          onClick={() => handleTokenClick(token)}
        >
          <div className='flex min-w-0 flex-1 items-center gap-[5px] px-2'>
            <TokenImage altName={token.name} logoURI={token.logoURI} tag={token?.isNewCoin ? 'NEW' : token?.isTopTrendingCoins ? 'HOT' : undefined}/>
            <span className='min-w-0 truncate'>{token.name}</span>
            <span
              className='bg-main text-main desk:hover:bg-opacity-10 flex h-[24px] max-w-[220px] cursor-pointer items-center rounded-xl bg-opacity-5 px-[10px] text-center text-xs'
              title={token.address.toLowerCase()}
              onClick={() => window.open(`https://birdeye.so/token/${token.address}?chain=solana`, '_blank', 'noopener,noreferrer')}
            >
              {prettyAddress(token.address.toLowerCase())}
            </span>
            <Conditional if={isSwapList && checkWhitelisted(token, SOL_ADDRESS)}>
              <IconLavarageLogo
                height={16}
                width={16}
                onClick={event => {
                  handleLavaClick(token)
                  event.stopPropagation()
                }}
              />
            </Conditional>
          </div>
          <span className='shrink-0 px-2 uppercase'>{token.symbol}</span>
        </div>
      </div>
    )
  })

  const dragScrollRef = useRef<HTMLDivElement>(null)
  const isDragging = useRef(false)
  const startX = useRef(0)
  const scrollLeft = useRef(0)
  const dragStarted = useRef(false)

  const onMouseDown = (e: React.MouseEvent) => {
    isDragging.current = true
    startX.current = e.pageX - (dragScrollRef.current?.offsetLeft || 0)
    scrollLeft.current = dragScrollRef.current?.scrollLeft || 0
    dragScrollRef.current!.style.cursor = 'grabbing'
    dragStarted.current = false

    document.addEventListener('mousemove', onMouseMove, true)
    document.addEventListener('mouseup', onMouseUp, true)
    e.stopPropagation()
  }

  const onMouseUp = (e: MouseEvent) => {
    isDragging.current = false
    dragScrollRef.current!.style.cursor = 'grab'

    document.removeEventListener('mousemove', onMouseMove, true)
    document.removeEventListener('mouseup', onMouseUp, true)
    e.stopPropagation()
  }

  const onMouseMove = (e: MouseEvent) => {
    if (!isDragging.current) return
    e.preventDefault()
    const x = e.pageX - (dragScrollRef.current?.offsetLeft || 0)
    const walk = (x - startX.current) * 2
    if (dragScrollRef.current) {
      dragScrollRef.current.scrollLeft = scrollLeft.current - walk
    }
    dragStarted.current = true
    e.stopPropagation()
  }

  const handleCoinButtonClick = (token: TokenInfo) => {
    if (!dragStarted.current) {
      handleTokenClick(token)
    }
  }

  const handleEmojiClick = ({ emoji }: { emoji: string }) => {
    setInputValue(prev => prev + emoji)
    setIsEmojiPickerVisible(false)
  }

  return (
    <div className='w-full'>
      <CoinButton hasDropdown className='cursor-pointer' isWhitelisted={checkWhitelisted(baseToken, quoteToken.address)} loading={!baseToken?.address} token={baseToken} onClick={toggleModal}/>
      <Conditional if={isModalVisible}>
        <Modal>
          <div ref={wrapperRef} className='bg-vibrant modal-box h-[554px] max-w-[380px] overflow-x-hidden px-0 pb-[20px] pt-[10px] text-xs'>

            <div className='mb-[10px] flex items-center justify-between px-[10px]'>
              <div className='bg-main desk:hover:bg-opacity-10 flex grow items-center rounded-lg bg-opacity-5 text-white transition'>
                <div className='ml-[5px]'>
                  <IconSearch/>
                </div>
                <input
                  className='h-[36px] w-full rounded-[8px] bg-transparent p-2 text-base outline-none placeholder:text-white placeholder:text-opacity-40'
                  placeholder='Search...'
                  type='text'
                  value={inputValue}
                  onBlur={() => inputValue !== '' && trackTokenSearchGA(inputValue)}
                  onChange={handleInputChange}
                />
                <div className='mr-[10px]'>
                  <button onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)}>
                    <span aria-label='emoji-picker' className='text-base' role='img'>😉</span>
                  </button>
                </div>
                <div className='absolute bottom-0 z-50'>
                  <EmojiPicker open={isEmojiPickerVisible} theme={Theme.DARK} onEmojiClick={({ emoji }) => handleEmojiClick({ emoji })}/>
                </div>
              </div>
              <button className='ml-[5px]' onClick={toggleModal}>
                <IconX className='btn-icon'/>
              </button>
            </div>
            <Conditional if={listMode !== 'baseSwap' && listMode !== 'quoteSwap'}>
              <div
                ref={dragScrollRef}
                className='hide-scrollbar user-select-none -mt-2 mb-5 flex h-[52px] gap-2 overflow-x-auto px-[10px] pt-2'
                style={{ cursor: 'grab' }}
                onMouseDown={onMouseDown}
              >
                <Conditional if={!!topCoinFilteredByQuote.length}>
                  <For of={topCoinFilteredByQuote}>
                    {(topCoin, index) => {
                      if (topCoin) {
                        topCoin.isTopTrendingCoins = true
                      }
                      const isNewCoin = NEW_COIN_ADDRESSES_ARRAY.includes(topCoin?.address)
                      if (isNewCoin) {
                        topCoin.isNewCoin = true
                      }

                      return (
                        <CoinButton
                          key={index + topCoin?.symbol}
                          className='bg-alt desk:hover:bg-alt/20 bg-alt/10 cursor-col-resize transition'
                          token={topCoin}
                          onClick={() => handleCoinButtonClick(topCoin)}
                        />
                      )
                    }}
                  </For>
                </Conditional>
                <Conditional if={!topCoinFilteredByQuote.length}>
                  <div className='flex w-full items-center justify-center'>
                    <span className='loading loading-spinner loading-md'/>
                  </div>
                </Conditional>
              </div>
            </Conditional>
            <Conditional if={listMode === 'default'}>
              <div className='tabs-boxed tabs bg-vibrant mx-auto mb-2 flex h-9 w-[250px] rounded-lg p-1' role='tablist'>
                <div
                  className={clsxm(
                    'flex h-full w-1/2 items-center justify-center rounded-lg',
                    {
                      'cursor-default bg-alt bg-opacity-10 text-alt transition': tokensFilter === TokenListFilters.MARGIN,
                    },
                    {
                      'cursor-pointer transition desk:hover:opacity-70': tokensFilter === TokenListFilters.ALL,
                    },
                  )}
                  role='tab'
                  onClick={() => {
                    setTokensFilter(TokenListFilters.MARGIN)
                    scrollModalToTop()
                  }}
                >
                  <span className='mr-1'>Lava</span>
                  <IconLavarage height={16} width={16}/>
                  <span
                    className={clsxm('ml-[5px] rounded-full px-[5px] py-[2.5px] text-xs transition', {
                      'bg-alt text-main': tokensFilter === TokenListFilters.MARGIN,
                      'bg-main text-background': tokensFilter !== TokenListFilters.MARGIN,
                    })}
                  >
                    {quantityOfMarginTokens < 999 ? quantityOfMarginTokens : '999+'}
                  </span>
                </div>
                <div
                  className={clsxm(
                    'flex h-full w-1/2 items-center justify-center rounded-lg',
                    {
                      'cursor-default bg-alt bg-opacity-10 text-alt transition': tokensFilter === TokenListFilters.ALL,
                    },
                    {
                      'cursor-pointer transition desk:hover:opacity-70': tokensFilter === TokenListFilters.MARGIN,
                    },
                  )}
                  role='tab'
                  onClick={() => {
                    setTokensFilter(TokenListFilters.ALL)
                    scrollModalToTop()
                  }}
                >
                  All
                  <IconJupiter className='ml-1' height={16} width={16}/>
                </div>
              </div>
            </Conditional>
            <Conditional if={listMode !== 'default'}>
              <div className='tabs-boxed tabs bg-vibrant mx-auto mb-2 flex h-9 w-[250px] rounded-lg p-1' role='tablist'>
                <div
                  className={clsxm(
                    'flex h-full w-full cursor-default items-center justify-center gap-[5px] rounded-lg',
                    {
                      ' bg-alt bg-opacity-10 text-alt': !isSwapList,
                      'bg-main bg-opacity-5 text-main': isSwapList,
                    },
                  )}
                >
                  <Conditional if={listMode === 'long' || listMode === 'short'}>
                    <span>Lava</span>
                    {/* <IconJupiter className='ml-1' height={16} width={16}/> */}
                    {/* <IconLogo width={16}/> */}
                    <IconLavarageLogo width={16}/>
                    <span
                      className={clsxm('rounded-full px-[5px] py-[2.5px] text-xs transition', {
                        'bg-alt text-main': tokensFilter === TokenListFilters.MARGIN,
                        'bg-main text-background': tokensFilter !== TokenListFilters.MARGIN,
                      })}
                    >
                      {quantityOfMarginTokens < 999 ? quantityOfMarginTokens : '999+'}
                    </span>
                  </Conditional>
                  <Conditional if={isSwapList}>
                    <span>All</span>
                    <IconJupiter className='ml-1' height={16} width={16}/>
                  </Conditional>
                </div>

              </div>
            </Conditional>
            <div className='h-max-[328px] w-full bg-transparent'>
              <Conditional if={!!isDebouncing}>
                <div className='flex h-[328px] w-full items-center justify-center'>
                  <span className='loading loading-spinner loading-lg my-10'/>
                </div>
              </Conditional>
              <Conditional if={!isDebouncing && filteredTokens.length}>
                <List ref={listRef} className='hide-scrollbar h-fit' height={328} itemCount={filteredTokens.length} itemSize={50} width='auto'>
                  {Row}
                </List>
              </Conditional>
              <Conditional if={!isDebouncing && !filteredTokens.length && areTokensLoading}>
                <div className='flex h-[328px] w-full items-center justify-center'>
                  <span className='loading loading-spinner loading-lg my-10'/>
                </div>
              </Conditional>
              <Conditional if={!isDebouncing && !filteredTokens.length && !areTokensLoading}>
                <div className='flex h-[328px] w-full items-center justify-center'>
                  <span className='text-[14px]'>No results found, please revise your search.</span>
                </div>
              </Conditional>
            </div>
            <Conditional if={tokensFilter === TokenListFilters.MARGIN}>
              <div className='flex justify-center pt-[20px]'>
                <span>
                  Missing a token on this list?
                  {' '}
                  <a href='https://discord.com/invite/lavarage' rel='noopener noreferrer' target='_blank'>
                    <span className='text-alt border-b hover:border-b-0'>Let us know on Discord!</span>
                  </a>
                </span>
              </div>
            </Conditional>
            <Conditional if={tokensFilter === TokenListFilters.ALL}>
              <div className='flex justify-center pt-[10px]'>
                <span className='px-2 text-center'>
                  If you can't find a token in the list (from Jupiter), use the search bar to look it up by name or contract address.
                </span>
              </div>
            </Conditional>
          </div>
        </Modal>
      </Conditional>
    </div>
  )
})
