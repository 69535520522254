import { Conditional, FocusControlledNumericInput, LSpan } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { usePreferencesStore } from '@/app/stores'
import { IconMinus, IconPlus, IconWhiteMinus, IconWhitePlus } from '@/assets/svgs'
import { MIN_LEVERAGE } from '@/config'
import { type HTMLAttributes, memo, useEffect, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'

type Props = HTMLAttributes<HTMLDivElement> & {
  maxLeverage: number | null
  isLoading: boolean
  isWhitelisted: boolean
}

export const LeverageField = memo(({ maxLeverage, isLoading, isWhitelisted }: Props) => {
  const [leverage, setLeverage, defaultLeverage, setDefaultLeverage, baseTokenLong] = usePreferencesStore(useShallow(s => [s.leverage, s.setLeverage, s.defaultLeverage, s.setDefaultLeverage, s.baseTokenLong]))
  const [prevBaseTokenAddress, setPrevBaseTokenAddress] = useState(baseTokenLong?.address || '')
  useEffect(() => {
    if (!baseTokenLong?.address || maxLeverage === null || !isWhitelisted || leverage === null) return
    const newDefaultLeverage = Math.round(((maxLeverage + 1) / 2) * 10) / 10
    const currentLeverage = parseFloat(leverage)
    if (leverage === null || leverage === '' || currentLeverage < MIN_LEVERAGE) {
      // setLeverage('1')
      setDefaultLeverage(newDefaultLeverage.toString())
      return
    }
    if (maxLeverage && currentLeverage && currentLeverage > maxLeverage && maxLeverage > 1) {
      if (prevBaseTokenAddress === baseTokenLong?.address) {
        setLeverage(maxLeverage?.toString())
      }
      else {
        // setLeverage(null)
        setDefaultLeverage(newDefaultLeverage.toString())
        setPrevBaseTokenAddress(baseTokenLong?.address)
      }
    }
  }, [maxLeverage, prevBaseTokenAddress])

  return (
    <div
      className={clsxm(
        'flex justify-between gap-[10px] text-base',
        {
          'text-alt': isWhitelisted,
        },
        {
          'text-main text-opacity-40': !isWhitelisted,
        },
      )}
    >
      <Conditional if={isWhitelisted}>
        <button
          className={clsxm(
            'flex h-11 w-[56px] items-center justify-center rounded-lg bg-opacity-10',
            {
              'cursor-pointer bg-alt bg-opacity-10 transition desk:hover:bg-opacity-20': isWhitelisted,
            },
            {
              'cursor-default bg-main bg-opacity-5': !isWhitelisted,
            },
          )}
          onClick={() => isWhitelisted && setLeverage(MIN_LEVERAGE.toString())}
        >
          1x
        </button>
      </Conditional>
      <div className='flex flex-1 justify-between'>
        <button
          className={clsxm(
            'flex h-11 w-11 cursor-pointer items-center justify-center rounded-l-lg',
            {
              'bg-alt bg-opacity-10 transition': isWhitelisted,
            },
            {
              'bg-main bg-opacity-5': !isWhitelisted,
            },
            {
              'bg-alt bg-opacity-10 transition desk:hover:bg-opacity-20': MIN_LEVERAGE.toString() !== leverage && isWhitelisted,
            },
            {
              'cursor-default': MIN_LEVERAGE.toString() === leverage || !isWhitelisted,
            },
          )}
          onClick={() => isWhitelisted && setLeverage(Math.max(MIN_LEVERAGE, parseFloat((parseFloat(leverage || defaultLeverage) - 0.1).toFixed(1))).toString())}
        >
          <div
            className={clsxm({
              'opacity-40': MIN_LEVERAGE.toString() === leverage || !isWhitelisted,
            })}
          >
            {isWhitelisted ? <IconMinus/> : <IconWhiteMinus/>}
          </div>
        </button>
        <label
          className={clsxm(
            'py-auto flex flex-1',
            'flex w-full items-center justify-center',
            {
              'cursor-text bg-alt bg-opacity-10': isWhitelisted,
            },
            {
              'cursor-default bg-main bg-opacity-5': !isWhitelisted,
            },
          )}
        >
          <FocusControlledNumericInput
            isLeverage
            defaultValue={isWhitelisted ? 1.1 : MIN_LEVERAGE}
            disabled={!isWhitelisted}
            MAX_VALUE={maxLeverage}
            MIN_VALUE={MIN_LEVERAGE}
            setValue={setLeverage}
            value={isWhitelisted ? leverage || defaultLeverage : MIN_LEVERAGE.toString()}
          />
        </label>

        <button
          className={clsxm(
            'flex h-11 w-11 cursor-pointer items-center justify-center rounded-r-lg',
            {
              'bg-alt bg-opacity-10 transition': isWhitelisted,
            },
            {
              'bg-main bg-opacity-5': !isWhitelisted,
            },
            {
              'transition desk:hover:bg-opacity-20': maxLeverage?.toString() !== leverage && isWhitelisted,
            },
            {
              'cursor-default': maxLeverage?.toString() === (leverage || defaultLeverage) || !isWhitelisted,
            },
          )}
          onClick={() => isWhitelisted && maxLeverage !== null && setLeverage(Math.min(maxLeverage, parseFloat((parseFloat(leverage || defaultLeverage) + 0.1).toFixed(1))).toString())}
        >
          <div
            className={clsxm({
              'opacity-40': maxLeverage?.toString() === (leverage || defaultLeverage) || !isWhitelisted,
            })}
          >
            {isWhitelisted ? <IconPlus/> : <IconWhitePlus/>}
          </div>
        </button>
      </div>
      <Conditional if={isWhitelisted}>
        <button
          className={clsxm(
            'flex h-11 w-[56px] items-center justify-center rounded-lg bg-opacity-10',
            {
              'cursor-pointer bg-alt bg-opacity-10 transition desk:hover:bg-opacity-20': isWhitelisted,
            },
            {
              'cursor-default bg-main bg-opacity-5': !isWhitelisted,
            },
          )}
          onClick={() => isWhitelisted && maxLeverage !== null && setLeverage(maxLeverage?.toString())}
        >
          <LSpan isLoading={isLoading || !maxLeverage} text={`${maxLeverage?.toString()}x`} textSize={16}/>
        </button>
      </Conditional>
    </div>
  )
})
