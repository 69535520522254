import { useLavarage } from '@/app/providers/LavarageProvider'
import { usePreferencesStore } from '@/app/stores'
import { formatLamportsToSol } from '@/utils'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { ISC_ADDRESS, USDC_ADDRESS } from '../../config.js'

type BaseBalance = {
  amount: number | null
  symbol: string | null
}

export type UserBalance = {
  SOL: number | null
  ISC: number | null
  USDC: number | null
  BASE: BaseBalance
  QUOTE: BaseBalance
}

type Balance = {
  userBalance: UserBalance
  connected: boolean
  connecting: boolean
  publicKey: PublicKey | null
}

const activeSubscriptions: Record<string, number> = {}

export function useBalance(): Balance {
  const lavarages = useLavarage()
  const lavarage = lavarages[0]
  const [baseTokenSwap, quoteTokenSwap, userBalance, setUserBalance] = usePreferencesStore(useShallow(s => [s.baseTokenSwap, s.quoteTokenSwap, s.userBalance, s.setUserBalance]))
  const { connected, connecting, publicKey } = useWallet()
  const { connection } = lavarage.program.provider
  useEffect(() => {
    if (!lavarage || !publicKey || !connected) {
      setUserBalance({ SOL: null, ISC: null, USDC: null, BASE: { amount: null, symbol: null }, QUOTE: { amount: null, symbol: null } })
      return
    }

    const fetchBalances = async () => {
      try {
        const lamports = await connection.getBalance(publicKey)
        const sol = formatLamportsToSol(lamports).toNumber()

        const iscMintAddress = new PublicKey(ISC_ADDRESS)
        const iscAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
          mint: iscMintAddress,
        })
        const iscBalance =
        iscAccounts.value?.[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0

        const usdcMintAddress = new PublicKey(USDC_ADDRESS)
        const usdcAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
          mint: usdcMintAddress,
        })
        const usdcBalance =
        usdcAccounts.value?.[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0

        const baseMintAddress = new PublicKey(baseTokenSwap.address)
        const baseAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
          mint: baseMintAddress,
        })
        const baseBalance =
        baseAccounts.value?.[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0

        const quoteMintAddress = new PublicKey(quoteTokenSwap.address)
        const quoteAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
          mint: quoteMintAddress,
        })
        const quoteBalance = quoteAccounts.value?.[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0

        setUserBalance({ SOL: sol, ISC: iscBalance, USDC: usdcBalance, BASE: { amount: baseBalance, symbol: baseTokenSwap.symbol }, QUOTE: { amount: quoteBalance, symbol: quoteTokenSwap.symbol } })
      }
      catch (error) {
        console.error('Error fetching balances:', error)
        setUserBalance({ SOL: null, ISC: null, USDC: null, BASE: { amount: null, symbol: null }, QUOTE: { amount: null, symbol: null } })
      }
    }

    fetchBalances()

    const pubKeyString = publicKey.toString()

    if (!activeSubscriptions[pubKeyString]) {
      const subscriptionId = connection.onAccountChange(publicKey, async () => {
        fetchBalances()
      })

      activeSubscriptions[pubKeyString] = subscriptionId
    }

    return () => {
      if (activeSubscriptions[pubKeyString]) {
        connection.removeAccountChangeListener(activeSubscriptions[pubKeyString])
        delete activeSubscriptions[pubKeyString]
      }
    }
  }, [lavarage, publicKey, connected, setUserBalance, baseTokenSwap.address, quoteTokenSwap.address])
  // useEffect(() => {
  //   const updateBaseBalance = async () => {
  //     try {
  //       if (!lavarage || !publicKey || !connected) {
  //         setUserBalance({ SOL: null, ISC: null, USDC: null, BASE: { amount: null, symbol: null }, QUOTE: { amount: null, symbol: null } })
  //         return
  //       }
  //       const baseMintAddress = new PublicKey(baseTokenSwap.address)
  //       const baseAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
  //         mint: baseMintAddress,
  //       })
  //       const baseBalance =
  //       baseAccounts.value?.[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0

  //       setUserBalance({ ...userBalance, BASE: { amount: baseBalance, symbol: baseTokenSwap.symbol } })
  //     }
  //     catch (error) {
  //       console.error('Error fetching balances:', error)
  //       setUserBalance({ ...userBalance, BASE: { amount: null, symbol: null } })
  //     }
  //   }
  //   if (baseTokenSwap.address) {
  //     updateBaseBalance()
  //   }
  // }, [baseTokenSwap.address])

  // useEffect(
  //   () => {
  //     const updateQuoteBalance = async () => {
  //       try {
  //         if (!lavarage || !publicKey || !connected) {
  //           setUserBalance({ SOL: null, ISC: null, USDC: null, BASE: { amount: null, symbol: null }, QUOTE: { amount: null, symbol: null } })
  //           return
  //         }
  //         const quoteMintAddress = new PublicKey(quoteTokenSwap.address)
  //         const quoteAccounts = await connection.getParsedTokenAccountsByOwner(publicKey, {
  //           mint: quoteMintAddress,
  //         })
  //         const quoteBalance = quoteAccounts.value?.[0]?.account?.data?.parsed?.info?.tokenAmount?.uiAmount || 0

  //         setUserBalance({ ...userBalance, QUOTE: { amount: quoteBalance, symbol: quoteTokenSwap.symbol } })
  //       }
  //       catch (error) {
  //         console.error('Error fetching balances:', error)
  //         setUserBalance({ ...userBalance, QUOTE: { amount: null, symbol: null } })
  //       }
  //     }
  //     if (quoteTokenSwap.address) {
  //       updateQuoteBalance()
  //     }
  //   }
  //   , [quoteTokenSwap.address],
  // )

  return { userBalance, connected, connecting, publicKey }
}
