import { type TradeModeType } from '../MainPage.js'
import { TradeTabItem } from './TradeTabItem.js'
import { TradeTabsList } from './TradeTabsList.js'

type TabControlProps = {
  currentTab: TradeModeType
  setCurrentTab: (t: TradeModeType) => void
}

interface TabItemProps {
  tab: TradeModeType
  currentTab: TradeModeType
  setCurrentTab: (t: TradeModeType) => void
  label: string
}

const TabItem = ({ tab, currentTab, setCurrentTab, label }: TabItemProps) => {
  const isActive = currentTab === tab
  const handleClick = () => {
    setCurrentTab(tab)
  }

  return <TradeTabItem handleClick={handleClick} isActive={isActive} label={label}/>
}

export const TradeModeTabControl = ({ currentTab, setCurrentTab }: TabControlProps) => {
  return (
    <TradeTabsList>
      <TabItem currentTab={currentTab} label='Def' setCurrentTab={setCurrentTab} tab='default'/>
      <TabItem currentTab={currentTab} label='Long' setCurrentTab={setCurrentTab} tab='long'/>
      <TabItem currentTab={currentTab} label='Swap' setCurrentTab={setCurrentTab} tab='swap'/>
      <TabItem currentTab={currentTab} label='Short' setCurrentTab={setCurrentTab} tab='short'/>
    </TradeTabsList>
  )
}
