import { Conditional, LSpan, NumericTokenInput, SlippageModal, TokenImage, UsdSwitch } from '@/app/components'
import { clsxm } from '@/app/helpers/clsxm'
import { usePoolsQuery } from '@/app/hooks/queries'
import { useBalance } from '@/app/hooks/useBalance'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { Layout } from '@/app/pages/layout/Layout'
import { useBorrow } from '@/app/pages/main/hooks/useBorrow'
import { useSwap } from '@/app/pages/main/hooks/useSwap'
import { getActionMainButtonMode } from '@/app/pages/main/utils/getActionMainButtonMode'
import { useAlertsStore, usePreferencesStore } from '@/app/stores'
import { CRYPTO_DECIMAL_POINTS, FIAT_DECIMAL_POINTS, ISC_ADDRESS, JUPITER_PLATFORM_FEE_BPS_HAS_LAVA_NFT, JUPITER_PLATFORM_FEE_BPS_NO_LAVA_NFT, MIN_LEVERAGE, MIN_PLATFORM_FEE_IN_SOL, MIN_PLATFORM_FEE_IN_STABLE, PLATFORM_FEE, SOL_ADDRESS, SOL_LOGO_URI, SOL_TOKEN } from '@/config'
import { coreService } from '@/services/CoreService'
import { getRelatedPool, stringToNumber } from '@/utils'
import { formatCurrency, formatSol } from '@/utils/formatters'
import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useShallow } from 'zustand/react/shallow'
import { IconReverse, IconWallet } from '../../../assets/index.js'
import { IS_MULTI_CURRENCY_ENABLED, IS_PROD } from '../../app.config.js'
import { Hint } from '../../components/Hint.js'
import { LabelledValue } from '../../components/LabelledValue.js'
import { useNftAccess } from '../../hooks/useNftAccess.js'
import { usePriceContext } from '../../providers/PriceProvider.js'
import { ActionMainButton, LeverageField, LoanSummary, TokenList } from './components'
import { QuoteTokenList } from './components/QuoteTokenList.js'
import { TradeModeTabControl } from './components/TradeModeTabControl.js'
import { checkWhitelisted } from './utils/checkWhitlisted.js'

export type TradeDetailsGA = {
  leverage: number
  quoteAmount: number
  baseAmount: number | null
  token: string
  fee: number
  total: number
  positionSize: number
  dailyInterest: number
  entryPrice: number | null
  liquidationPrice: number
}

export type TradeModeType = 'default' | 'long' | 'swap' | 'short'

const arr = ['default', 'long', 'swap', 'short']
type QuoteTokenSymbol = 'ISC' | 'SOL' | 'USDC'

const getCurrentTabFromPath = (path: string): TradeModeType => {
  switch (path) {
    case '/long':
      return 'long'
    case '/swap':
      return 'swap'
    case '/short':
      return 'short'
    default:
      return 'default'
  }
}
const getLabelOfFirstSection = (mod: TradeModeType): string => {
  switch (mod) {
    case 'long':
      return 'YOU DEPOSIT'
    case 'swap':
      return 'YOU SELL'
    case 'short':
      return 'YOU DEPOSIT'
    default:
      return 'YOU PAY'
  }
}

const getLabelOfSecondSection = (mod: TradeModeType): string => {
  switch (mod) {
    case 'long':
      return 'YOU LONG'
    case 'swap':
      return 'YOU BUY'
    case 'short':
      return 'YOU SHORT'
    default:
      return 'YOU LONG'
  }
}

export function MainPage() {
  const navigate = useNavigate()
  const location = useLocation()
  const tradeMode = getCurrentTabFromPath(window.location.pathname)
  const handleTabChange = (tab: TradeModeType) => navigate({ pathname: `/${tab === 'default' ? '' : tab}`, search: location.search })
  const [leverage, defaultLeverage, baseTokenLong, baseTokenSwap, setBaseTokenSwap, slippage, solInput, setSolInput, isUsdModeOn, setIsUsdModeOn, quoteTokenLong, setQuoteTokenLong, quoteTokenSwap, setQuoteTokenSwap, setLeverage] = usePreferencesStore(useShallow(s => [
    s.leverage,
    s.defaultLeverage,
    s.baseTokenLong,
    s.baseTokenSwap,
    s.setBaseTokenSwap,
    s.slippage,
    s.solInput,
    s.setSolInput,
    s.isUsdModeOn.trading,
    () => s.setIsUsdModeOn({ ...s.isUsdModeOn, trading: !s.isUsdModeOn.trading }),
    s.quoteTokenLong,
    s.setQuoteTokenLong,
    s.quoteTokenSwap,
    s.setQuoteTokenSwap,
    s.setLeverage,
  ]))
  const baseToken = tradeMode === 'swap' ? baseTokenSwap : baseTokenLong
  const quoteToken = tradeMode === 'swap' ? quoteTokenSwap : quoteTokenLong
  const setQuoteToken = tradeMode === 'swap' ? setQuoteTokenSwap : setQuoteTokenLong
  const handleClickReverse = () => {
    // setSolInput(baseTokenAmount ? formatSol(baseTokenAmount, baseToken?.decimals) : '')
    const newBaseTokenSwap = quoteTokenSwap
    setQuoteTokenSwap(baseTokenSwap)
    setBaseTokenSwap(newBaseTokenSwap)
  }
  // setBaseToken(defaultTokens.base)

  // useEffect(() => {
  //   setQuoteToken(defaultTokens.quote)
  //   if (quoteToken === null) {
  //     setQuoteToken(defaultTokens.quote)
  //   }
  //   if (baseToken === null) {
  //     setBaseToken(defaultTokens.base)
  //   }
  //   if (quoteToken?.address === baseToken?.address) {
  //     setBaseToken(defaultTokens.base)
  //     setQuoteToken(defaultTokens.quote)
  //   }
  // }, [])
  if (!baseToken || !quoteToken) return
  const { priceSol, priceUsdc, solPriceLoading, solPriceError, usdcPriceLoading, usdcPriceError, quotePrice, quotePriceLoading, quotePriceError, refresh } = usePriceContext()
  const getUSDCPrice = (tokenAddress: string) => {
    return tokenAddress === ISC_ADDRESS ? BigNumber(1) : BigNumber(priceUsdc[tokenAddress]?.price)
  }
  const [amountFromIx, setAmountFromIx] = useState(0)
  const { hasLavaRockNFT } = useNftAccess()
  const decimalPoints = isUsdModeOn ? FIAT_DECIMAL_POINTS : CRYPTO_DECIMAL_POINTS
  const [loading, confirming] = useAlertsStore(useShallow(state => [state.loading, state.confirming]))
  const { userBalance, connected, connecting } = useBalance()
  const isWhitelisted = checkWhitelisted(baseToken, quoteToken.address)
  const currentLeverage = isWhitelisted ? (leverage ?? defaultLeverage ?? MIN_LEVERAGE.toString()) : MIN_LEVERAGE.toString()
  // TODO: this is a temporary fix to fix the issue for the users who have the leverage set to NaN. We can remove it letter
  if (BigNumber(parseFloat(currentLeverage)).isNaN()) {
    setLeverage('1')
  }
  const isOnline = useNetworkStatus()
  // const [maxLeverage, setMaxLeverage] = useState<number | null>(null)

  const qtPrice = quoteToken?.address !== SOL_ADDRESS ? 1 / parseFloat(priceUsdc[SOL_ADDRESS]?.price) : parseFloat(priceSol[SOL_ADDRESS]?.price)
  const isBaseTokenAmountLoading = solPriceLoading || usdcPriceLoading || quotePriceLoading
  const numericSolInput = stringToNumber(solInput) || 0
  const openFee = coreService.openFee({ baseCurrency: numericSolInput, leverage: parseFloat(currentLeverage), fee: PLATFORM_FEE })
  const isMinFee = quoteToken.address !== SOL_ADDRESS ? openFee < MIN_PLATFORM_FEE_IN_STABLE : openFee < MIN_PLATFORM_FEE_IN_SOL
  const fees = isMinFee ? (quoteToken.address !== SOL_ADDRESS ? MIN_PLATFORM_FEE_IN_STABLE : MIN_PLATFORM_FEE_IN_SOL) : openFee
  const jupiterPlatformFeeBps = hasLavaRockNFT ? JUPITER_PLATFORM_FEE_BPS_HAS_LAVA_NFT : JUPITER_PLATFORM_FEE_BPS_NO_LAVA_NFT
  const jupiterPlatformFee = numericSolInput * parseFloat(currentLeverage) * jupiterPlatformFeeBps / 10_000

  // const getPriceBaseTokenInQuoteToken = (amount: number) => {
  //   if (isBaseTokenAmountLoading) return null
  //   if (quoteToken?.symbol === 'USDC') {
  //     return priceUsdc[baseToken?.address]?.price ? priceUsdc[baseToken?.address]?.price * amount : null
  //   }
  //   if (quoteToken?.symbol === 'SOL') {
  //     return priceSol[baseToken?.address]?.price ? priceSol[baseToken?.address]?.price * amount : null
  //   }
  //   return null
  // }
  const getBaseTokenAmount = () => {
    if (isBaseTokenAmountLoading) return null
    const spentAmountInSwap = numericSolInput * parseFloat(currentLeverage) - jupiterPlatformFee
    return quotePrice[baseToken?.address]?.price ? (1 / Number(quotePrice[baseToken?.address]?.price)) * spentAmountInSwap : null
  }
  const baseTokenAmount = getBaseTokenAmount()
  const availableFunds = userBalance?.[quoteToken?.symbol as QuoteTokenSymbol] ?? null
  const maxAvailableFunds = availableFunds ? availableFunds - fees : 0
  const isLeverageEqualToOne = parseFloat(currentLeverage) === 1 || !isWhitelisted
  const displayedFee = isLeverageEqualToOne ? jupiterPlatformFee : fees
  const total = numericSolInput + displayedFee
  const getMinTokenInputValue = (quoteSymbol: string) => {
    if (quoteSymbol === 'SOL') return 0.05
    if (quoteSymbol === 'USDC') return 10
    if (quoteSymbol === 'ISC') return 0.5
    return NaN
  }
  const minTokenInputValue = isLeverageEqualToOne ? 0 : getMinTokenInputValue(quoteToken.symbol)
  const initialMarginForCalcSummary = numericSolInput
  const baseTokenAmountForCalcSummary = amountFromIx > 0 ? amountFromIx : baseTokenAmount
  const leverageForCalcSummary = Math.max(parseFloat(currentLeverage), 1)

  const borrowedAmountForCalcSummary = BigNumber(coreService.borrowedAmount({ initialMargin: isNaN(initialMarginForCalcSummary) ? 0 : initialMarginForCalcSummary, leverage: leverageForCalcSummary }) || 0)
  // POSSIBLE REGRESSION! DO NOT CHANGE THIS BACK!!!!!!!!!
  const positionSize = borrowedAmountForCalcSummary.plus(initialMarginForCalcSummary).toNumber()
  const entryPrice = baseTokenAmountForCalcSummary ? (initialMarginForCalcSummary * leverageForCalcSummary) / baseTokenAmountForCalcSummary : 0
  const liquidationPrice = coreService.liquidationPrice({
    borrowedAmount: borrowedAmountForCalcSummary,
    interestAccrued: 0,
    collateralValue: baseTokenAmountForCalcSummary || 0,
    liquidationLTV: 90,
  })
  const tradeDetailsGA: TradeDetailsGA = {
    leverage: parseFloat(currentLeverage),
    quoteAmount: stringToNumber(solInput),
    baseAmount: baseTokenAmount,
    token: baseToken?.symbol,
    fee: fees,
    total,
    positionSize,
    dailyInterest: baseToken?.dailyInterest || 0,
    entryPrice,
    liquidationPrice,
  }
  const { borrow } = useBorrow({
    solAmount: stringToNumber(solInput),
    baseTokenAmount,
    toUSDC: priceUsdc,
    baseToken,
    total,
    slippage: parseFloat(slippage),
    leverage: parseFloat(currentLeverage),
    setAmountFromIx,
    tradeDetailsGA,
    jupiterPlatformFeeBps,
  })
  const { swap } = useSwap({
    from: quoteToken?.address,
    to: baseToken?.address,
    amount: stringToNumber(solInput),
    slippage: parseFloat(slippage),
    decimalsOfBaseToken: baseToken?.decimals,
    setAmountFromIx,
    tradeDetailsGA,
    jupiterPlatformFeeBps,
  })

  const { pools } = usePoolsQuery()
  const relatedPool = getRelatedPool(baseToken, pools, quoteToken?.address)
  useEffect(
    () => {
      if (!quoteToken) return
      if (quoteToken.symbol === 'USDC' && isUsdModeOn) {
        setIsUsdModeOn()
      }
    }
    , [quoteToken],
  )
  useEffect(
    () => {
      if (!IS_MULTI_CURRENCY_ENABLED) {
        if (quoteToken.symbol !== 'SOL') {
          setQuoteToken(SOL_TOKEN)
        }
      }
    }
    , [],
  )

  const marketPriceOfBasedTokenInQuoteCurrency = quotePrice[baseToken.address]?.price
  const maxBorrowLTV = relatedPool ? BigNumber.min(relatedPool.maxBorrow.dividedBy(marketPriceOfBasedTokenInQuoteCurrency), BigNumber(0.875)) : null
  const maxLeverage = maxBorrowLTV ? BigNumber(1).dividedBy(BigNumber(1).minus(maxBorrowLTV)).multipliedBy(10).minus(1).dividedBy(10).multipliedBy(0.95).decimalPlaces(1).toNumber() : null
  const isMaxLeverageLoading = maxLeverage === null

  const borrowedAmount = coreService.borrowedAmount({
    initialMargin: numericSolInput,
    leverage: parseFloat(currentLeverage),
  })
  // console.log('relatedPool', relatedPool, Number(relatedPool?.maxExposure), Number(relatedPool?.nodeWallet?.totalFunds))
  // TODO: wrong type in nodeWallet
  const availableLiquidity = relatedPool?.nodeWallet?.totalFunds ? BigNumber.minimum(
    relatedPool?.nodeWallet?.totalFunds || 0,
    relatedPool.maxExposure.eq(0) ? relatedPool?.nodeWallet?.totalFunds || 0 : relatedPool.maxExposure.minus(relatedPool.currentExposure),
  ).toNumber() || 0 : -1

  const localFormatCurrency = useCallback(
    (val: number | null, decimalPointsProps = decimalPoints) => {
      if (val === null) return null
      if (val === undefined || isNaN(val) || !isFinite(val)) return '--'
      return formatCurrency(val, priceUsdc, isUsdModeOn, decimalPointsProps, quoteToken)
    },
    [priceUsdc, isUsdModeOn, decimalPoints],
  )
  const actionMainButtonMode = getActionMainButtonMode({
    isOnline,
    connected,
    confirming,
    solInput,
    maxAvailableFunds,
    leverage: parseFloat(currentLeverage),
    borrowedAmount,
    availableLiquidity,
    loading,
    tradeMode,
  })
  const youReceiveWithoutFee = (amountFromIx > 0 ? amountFromIx : baseTokenAmount) || 0
  const displayedYouReceive = formatSol(youReceiveWithoutFee, baseToken?.decimals)

  const quoteUnit = ` ${quoteToken?.symbol?.toLocaleUpperCase()}`
  const youPayHintMinText = `${getMinTokenInputValue(quoteToken.symbol)} ${quoteToken.symbol}`

  return (
    <Layout>
      <div
        className={clsxm('flex min-h-[665px] w-full max-w-[460px] flex-col justify-center', {
          'pointer-events-none': amountFromIx > 0,
        })}
      >
        <div className='mb-[10px] flex justify-between'>
          <div className='flex justify-start'>
            <Conditional if={!IS_PROD}>
              <TradeModeTabControl currentTab={tradeMode as TradeModeType} setCurrentTab={handleTabChange}/>
            </Conditional>
          </div>
          <div className='flex justify-end'>
            <Conditional if={!isLeverageEqualToOne && quoteToken.symbol !== 'USDC'}>
              <UsdSwitch className='mr-[10px]' isActive={isUsdModeOn} quoteSymbol={quoteToken.symbol} onClick={setIsUsdModeOn}/>
            </Conditional>
            <SlippageModal/>
          </div>
        </div>
        <section className='bg-main/5 mb-[10px] flex h-[130px] flex-col flex-wrap rounded-lg p-[10px]'>
          <div className='flex justify-between text-xs'>
            <div className='flex items-center gap-[5px]'>
              <div className='text-main opacity-40'>
                {getLabelOfFirstSection(tradeMode)}
              </div>
              <Conditional if={!isLeverageEqualToOne}>
                <Hint text={`This is the initial margin for this position (min: ${youPayHintMinText} required)`}/>
              </Conditional>
            </div>
            <div
              className='desk:hover:opacity-70 flex cursor-pointer gap-[5px] transition'
              onClick={() => {
                if (availableFunds && availableFunds >= 0.000001) {
                  // Without 1_000_000 a very small number is displayed as zero
                  setSolInput((Math.floor((availableFunds - 0.05) * 1_000_000) / 1_000_000).toString())
                }
              }}
            >
              <Conditional if={!connecting && connected}>
                <Conditional if={tradeMode !== 'swap'}>
                  <span className='opacity-40'>AVAILABLE FUNDS: </span>
                  <LSpan isLoading={availableFunds === null} text={availableFunds === null ? null : formatSol(availableFunds)} textSize={12} unit={quoteUnit}/>
                </Conditional>
                <Conditional if={tradeMode === 'swap'}>
                  <div
                    className='flex gap-[5px]'
                  >
                    <IconWallet/>
                    <LSpan isLoading={userBalance.QUOTE?.amount === null} text={userBalance.QUOTE?.amount === null ? null : formatSol(userBalance.QUOTE?.amount)} textSize={12} unit={userBalance.QUOTE?.symbol ?? ''}/>
                  </div>
                </Conditional>
              </Conditional>
            </div>
          </div>
          <div className='my-auto flex items-center justify-between'>
            <div className='flex-none items-center'>
              {/* <Conditional if={tradeMode === 'borrow'}>
                <TokenList list={tradeMode === 'borrow' ? 'base' : 'quote'}/>
              </Conditional> */}
              {/* <Conditional if={tradeMode === 'borrow'}> */}
              {/* <TokenList/> */}
              {/* </Conditional> */}
              {/* <Conditional if={tradeMode !== 'borrow'}> */}
              <Conditional if={IS_MULTI_CURRENCY_ENABLED && tradeMode !== 'swap'}>
                <QuoteTokenList/>
              </Conditional>
              <Conditional if={IS_MULTI_CURRENCY_ENABLED && tradeMode === 'swap'}>
                <TokenList listMode='quoteSwap'/>
              </Conditional>
              <Conditional if={!IS_MULTI_CURRENCY_ENABLED}>
                <div className='flex min-w-[71px] items-center justify-start gap-[5px] rounded-lg py-[3px] pl-[8px]'>
                  <TokenImage altName='SOL' logoURI={SOL_LOGO_URI}/>
                  <span className='text-sm'>SOL</span>
                </div>
              </Conditional>
            </div>
            <div className='flex max-w-[250px] grow-0 items-center overflow-hidden'>
              <NumericTokenInput
                connected={connected}
                MAX_VALUE={maxAvailableFunds}
                MIN_VALUE={minTokenInputValue}
                refresh={refresh}
                setValue={setSolInput}
                size='l'
                value={solInput}
              />
            </div>
          </div>
          <div className='ml-auto h-[15px] text-xs opacity-40'>
            <Conditional if={!solPriceError && !usdcPriceError && !quotePriceError && priceUsdc && priceUsdc[SOL_ADDRESS] && quoteToken.symbol !== 'USDC' && quoteToken.symbol !== 'ISC'}>
              <p className='max-w-[200px] overflow-hidden'>
                <LSpan
                  isUnitLeft
                  isLoading={solPriceLoading || usdcPriceLoading || quotePriceLoading}
                  text={formatSol(getUSDCPrice(quoteToken.address).toNumber() * numericSolInput, FIAT_DECIMAL_POINTS)}
                  textSize={12}
                  unit='$'
                />
              </p>
            </Conditional>
            <Conditional if={!!solPriceError}>
              <span>Error has occurred</span>
            </Conditional>
          </div>
        </section>
        <Conditional if={tradeMode !== 'swap'}>
          <LeverageField isLoading={isMaxLeverageLoading} isWhitelisted={isWhitelisted} maxLeverage={maxLeverage}/>
        </Conditional>
        <Conditional if={tradeMode === 'swap'}>
          <div className='relative flex flex-col items-center py-[10px]'>
            <span className='flex w-full items-center justify-center gap-[10px]'>
              <span className='bg-main h-px grow bg-opacity-5'/>
              <div className='cursor-pointer' onClick={handleClickReverse}>
                <IconReverse/>
              </div>
              <span className='bg-main h-px grow bg-opacity-5'/>
            </span>
          </div>
        </Conditional>
        <section
          className={clsxm(
            'my-[10px] flex h-[130px] flex-col justify-between overflow-hidden rounded-lg bg-opacity-10 p-[10px]',
            {
              'bg-alt': isWhitelisted,
              'bg-main': !isWhitelisted,
            },
          )}
        >
          <div className='flex justify-between text-xs'>
            <div className='flex items-center gap-[5px]'>
              <div className='opacity-40'>
                {tradeMode === 'default' && isLeverageEqualToOne && 'YOU RECEIVE'}
                {tradeMode === 'default' && !isLeverageEqualToOne && 'YOU LONG'}
                {tradeMode !== 'default' && getLabelOfSecondSection(tradeMode)}
              </div>
              <Conditional if={!isLeverageEqualToOne}>
                <Hint position='bottom' text='This receivable amount may differ due to slippage.'/>
              </Conditional>
            </div>
            <Conditional if={tradeMode === 'swap'}>
              <div
                className='flex gap-[5px]'
              >
                <IconWallet/>
                <LSpan isLoading={userBalance.BASE?.amount === null} text={userBalance.BASE?.amount === null ? null : formatSol(userBalance.BASE?.amount)} textSize={12} unit={userBalance.BASE?.symbol ?? ''}/>
              </div>
            </Conditional>
            <Conditional if={isWhitelisted}>
              <Conditional if={tradeMode !== 'swap'}>
                <div
                  className='flex gap-[5px] transition'
                >
                  <span className='opacity-40'>MAX BORROW AMT: </span>
                  <LSpan
                    isLoading={isMaxLeverageLoading || solPriceLoading || availableLiquidity === -1}
                    text={formatSol(Math.max(availableLiquidity, 0))}
                    textSize={12}
                    unit={quoteUnit}
                  />
                </div>
              </Conditional>
            </Conditional>
          </div>
          <div className='flex gap-[10px]'>

            <div className='flex-none items-center'>
              <Conditional if={tradeMode === 'default'}>
                <TokenList listMode='default'/>
              </Conditional>
              <Conditional if={tradeMode === 'long'}>
                <TokenList listMode='long'/>
              </Conditional>
              <Conditional if={tradeMode === 'swap'}>
                <TokenList listMode='baseSwap'/>
              </Conditional>
              <Conditional if={tradeMode === 'short'}>
                <TokenList listMode='short'/>
              </Conditional>
            </div>
            <Conditional if={!solPriceError && priceSol && stringToNumber(solInput) >= 0 && leverage !== ''}>
              <div
                className={clsxm(
                  'flex flex-auto items-center justify-end gap-[5px] overflow-hidden text-right',
                  {
                    'text-alt': isWhitelisted,
                  },
                  {
                    'text-main': !isWhitelisted,
                  },
                )}
              >
                <LSpan
                  className='flex-auto truncate text-2xl font-bold'
                  isLoading={!baseToken?.address || (isWhitelisted && isMaxLeverageLoading) || isBaseTokenAmountLoading}
                  text={displayedYouReceive}
                  textSize={24}
                />
              </div>
            </Conditional>
          </div>
          <div className='ml-auto h-[15px] text-xs opacity-40'>
            <Conditional if={!usdcPriceError && priceUsdc && priceUsdc[baseToken?.address]}>
              <p>
                {`1${quoteUnit} = ${
                  amountFromIx > 0
                    ? formatSol(amountFromIx / numericSolInput / Number(currentLeverage), CRYPTO_DECIMAL_POINTS)
                    : formatSol(qtPrice || 0, CRYPTO_DECIMAL_POINTS)
                } ${baseToken?.symbol?.toLocaleUpperCase()}`}
              </p>
            </Conditional>

            <Conditional if={!!usdcPriceError}>
              <span>Error has occurred</span>
            </Conditional>
          </div>
        </section>
        <section className='mb-[20px] flex items-center justify-between gap-[10px] text-sm'>
          <div className='mb-[-60px] mr-[-120px] mt-[-50px] flex flex-auto flex-col gap-[5px] pb-[60px] pl-[5px] pr-[125px] pt-[50px]'>
            <LabelledValue
              hintInternalComponent={
                <>
                  <Conditional if={hasLavaRockNFT}>
                    <span>Fee reduced for </span>
                    <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                    <span> holder!</span>
                    <p className='mb-5'/>
                  </Conditional>
                  <Conditional if={!hasLavaRockNFT}>
                    <span>Own a </span>
                    <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                    <span> to reduce your fees!</span>
                    <p className='mb-5'/>
                  </Conditional>
                  <span>More details on </span>
                  <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/platform/fee' rel='noreferrer' target='_blank'>fees</a></span>
                </>
              }
              hintPosition='right'
              isLoading={isWhitelisted && isMaxLeverageLoading}
              label={`Fee ${isMinFee ? '' : '(0.5%)'}`}
              value={displayedFee === 0 ? 'Free' : localFormatCurrency(displayedFee)}
            />
            <Conditional if={!isLeverageEqualToOne}>
              <LabelledValue
                hintPosition='right'
                isLoading={isWhitelisted && isMaxLeverageLoading}
                label='Total'
                labelClassName='font-bold'
                labelHint={isLeverageEqualToOne ? undefined : 'This payable amount excludes network fees such as rent and gas.'}
                labelHintClassName='tooltip-right'
                value={localFormatCurrency(total)}
                valueClassName={clsxm('font-bold', {
                  'text-yellow': numericSolInput > maxAvailableFunds,
                })}
              />
            </Conditional>
          </div>
          <div className='flex-none'>
            <ActionMainButton actionMainButtonMode={actionMainButtonMode} borrow={borrow} loading={loading || isWhitelisted && isMaxLeverageLoading} short={borrow} swap={swap}/>
          </div>
        </section>
        <Conditional if={isWhitelisted && parseFloat(currentLeverage) !== MIN_LEVERAGE && tradeMode !== 'swap'}>
          <LoanSummary
            borrowedAmount={borrowedAmount}
            entryPrice={entryPrice}
            hasLavaRockNFT={hasLavaRockNFT}
            isLoading={isMaxLeverageLoading}
            liquidationPrice={liquidationPrice}
            localFormatCurrency={localFormatCurrency}
            positionSize={positionSize - jupiterPlatformFee}
          />
        </Conditional>
      </div>
    </Layout>
  )
}
