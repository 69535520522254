import { useLavarage } from '@/app/providers/LavarageProvider'
import { TokenService } from '@/services'
import { useQuery } from '@tanstack/react-query'
import { useCallback, useRef } from 'react'
import { NEW_COIN_ADDRESSES_ARRAY } from '../../app.config.js'

const tokenService = new TokenService()

export const useTokensQuery = (list: 'base' | 'quote' | 'all') => {
  const lavarages = useLavarage()
  // const [quoteToken] = usePreferencesStore(useShallow(s => [s.quoteToken]))
  // const lavarage = quoteToken?.symbol === 'SOL' ? lavarages[0] : lavarages[1]
  // const prevProgramIdRef = useRef<string | null>(null)

  // useEffect(() => {
  //   if (quoteToken && programIdAddresses[quoteToken.address]) {
  //     const newProgramId = programIdAddresses[quoteToken.address].programId
  //     if (newProgramId !== prevProgramIdRef.current) {
  //       setProgramId(newProgramId)
  //       prevProgramIdRef.current = newProgramId
  //     }
  //   }
  // }, [quoteToken, setProgramId])

  // const { pools, refetch: refetchPools } = usePoolsQuery()
  const fetchController = useRef<AbortController | null>(null)
  const firstRenderRef = useRef(true)

  const fetchTokens = useCallback(async () => {
    if (fetchController.current) {
      fetchController.current.abort()
    }

    fetchController.current = new AbortController()
    const signal = fetchController.current.signal

    try {
      if (list === 'all') {
        const fetchedTokens = await tokenService.fetchTokens({ cache: firstRenderRef.current, signal })
        fetchedTokens.forEach(token => {
          if (NEW_COIN_ADDRESSES_ARRAY.includes(token.address)) {
            token.isNewCoin = true
          }
        })
        return fetchedTokens
      }

      // const whitelistedTokenAddresses = pools.map(pool => pool.baseCurrency.address)

      // const fetchedTokens = await tokenService.revalidateBasedOnWhitelistedTokens({
      //   whitelistedAddresses: whitelistedTokenAddresses,
      //   cache: firstRenderRef.current,
      //   signal,
      // })

      if (signal.aborted) return

      firstRenderRef.current = false

      // const filteredTokens = fetchedTokens.filter(token => token.address !== SOL_ADDRESS)

      // filteredTokens.forEach(token => {
      //   if (NEW_COIN_ADDRESSES_ARRAY.includes(token.address)) {
      //     token.isNewCoin = true
      //   }
      // })

      // return filteredTokens
    }
    catch (error) {
      if (signal.aborted) return
      console.error('Error fetching tokens:', error)
      return []
    }
    finally {
      fetchController.current = null
    }
  }, [list, lavarages])

  const {
    data: tokens,
    refetch,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ['tokens', list],
    queryFn: fetchTokens,
    enabled: !!lavarages,
    refetchInterval: 60_000,
    retry: 5,
  })

  return { tokens, refetch, isLoading, isError }
}
