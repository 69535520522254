import { type TokenInfo } from '@/services/types'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'
import { USDC_TOKEN, WIF_TOKEN } from '../../config.js'
import { type UserBalance } from '../hooks/useBalance.js'
import { type TradeModeType } from '../pages/main/MainPage.js'

export interface IPreferencesState {
  solInput: string
  stakingInput: string
  unstakingInput: string
  leverage: string
  defaultLeverage: string
  slippage: string
  baseTokenLong: TokenInfo
  quoteTokenLong: TokenInfo
  baseTokenSwap: TokenInfo
  quoteTokenSwap: TokenInfo
  userBalance: UserBalance
  isUsdModeOn: { trading: boolean; positions: boolean; staking: boolean }
  hasLavaRockNFT: boolean
  isCheckingNFT: boolean
  tradeMode: TradeModeType
  isNotifiMenuVisible: boolean
  version: string
}

interface PreferencesStoreState extends IPreferencesState {
  setSolInput: (solInput: string) => void
  setStakingInput: (stakingInput: string) => void
  setUnstakingInput: (unstakingInput: string) => void
  setLeverage: (leverage: string) => void
  setDefaultLeverage: (defaultLeverage: string) => void
  setSlippage: (slippage: string) => void
  setBaseTokenLong: (token: TokenInfo) => void
  setQuoteTokenLong: (token: TokenInfo) => void
  setBaseTokenSwap: (token: TokenInfo) => void
  setQuoteTokenSwap: (token: TokenInfo) => void
  setUserBalance: (userBalance: UserBalance) => void
  setIsUsdModeOn: (isUsdModeOn: { trading: boolean; positions: boolean; staking: boolean }) => void
  setHasLavaRockNFT: (hasLavaRockNFT: boolean) => void
  setIsCheckingNFT: (isCheckingNFT: boolean) => void
  setTradeMode: (tradeMode: TradeModeType) => void
  setIsNotifiMenuVisible: (isNotifiMenuVisible: boolean) => void
  setVersion: (version: string) => void
}

export const usePreferencesStore = create<PreferencesStoreState>()(devtools(persist(
  set => ({
    userBalance: { SOL: null, ISC: null, USDC: null, BASE: { amount: null, symbol: null }, QUOTE: { amount: null, symbol: null } },
    solInput: '1',
    stakingInput: '1',
    unstakingInput: '1',
    leverage: '1.1',
    defaultLeverage: '1.1',
    baseTokenLong: WIF_TOKEN,
    quoteTokenLong: USDC_TOKEN,
    baseTokenSwap: WIF_TOKEN,
    quoteTokenSwap: USDC_TOKEN,
    slippage: '10',
    isUsdModeOn: { trading: false, positions: false, staking: false },
    hasLavaRockNFT: false,
    isCheckingNFT: false,
    tradeMode: 'default',
    isNotifiMenuVisible: false,
    version: '1.0.0',

    setUserBalance: userBalance => set({ userBalance }),
    setSolInput: solInput => set({ solInput }),
    setStakingInput: stakingInput => set({ stakingInput }),
    setUnstakingInput: unstakingInput => set({ unstakingInput }),
    setLeverage: leverage => set({ leverage }),
    setDefaultLeverage: defaultLeverage => set({ defaultLeverage }),
    setBaseTokenLong: baseTokenLong => set({ baseTokenLong }),
    setQuoteTokenLong: quoteTokenLong => set({ quoteTokenLong }),
    setBaseTokenSwap: baseTokenSwap => set({ baseTokenSwap }),
    setQuoteTokenSwap: quoteTokenSwap => set({ quoteTokenSwap }),
    setSlippage: slippage => set({ slippage }),
    setIsUsdModeOn: isUsdModeOn => set({ isUsdModeOn }),
    setHasLavaRockNFT: hasLavaRockNFT => set({ hasLavaRockNFT }),
    setIsCheckingNFT: isCheckingNFT => set({ isCheckingNFT }),
    setTradeMode: tradeMode => set({ tradeMode }),
    setIsNotifiMenuVisible: isNotifiMenuVisible => set({ isNotifiMenuVisible }),
    setVersion: version => set({ version }),
  }),
  { name: 'user-storage' },
)))
