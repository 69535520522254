import { useLavarage } from '@/app/providers/LavarageProvider'
import { type TokenInfo } from '@/services/types'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { useShallow } from 'zustand/react/shallow'
import { API_HOST } from '../../app.config.js'
import { usePreferencesStore } from '../../stores/index.js'

export const useTopCoinsQuery = (tokens: TokenInfo[]) => {
  const lavarages = useLavarage()
  const [quoteToken] = usePreferencesStore(useShallow(s => [s.quoteToken]))
  const lavarage = quoteToken?.symbol === 'SOL' ? lavarages[0] : lavarages[1]
  const fetchTopCoins = async () => {
    if (!tokens.length) return []
    const { data } = await axios.get('/trending-coins', { baseURL: API_HOST })
    const trendingCoins = data as TokenInfo[]

    const topTenTrendingCoins = trendingCoins.slice(0, 10)

    topTenTrendingCoins.forEach(coin => {
      const foundToken = tokens.find(token => token.address === coin[0])

      if (foundToken) {
        foundToken.isTopTrendingCoins = true
      }
    })

    return tokens.filter(coin => coin.isTopTrendingCoins)
  }

  const {
    data: topCoins,
    isLoading,
    isError,
    refetch,
  } = useQuery({
    queryKey: ['topCoins', tokens.length, `${tokens[0]?.address} ${tokens[tokens.length - 1]?.address}`],
    queryFn: fetchTopCoins,
    initialData: [],
    enabled: !!lavarage,
  })

  return {
    topCoins,
    isLoading,
    isError,
    refetch,
  }
}
