import {
  trackRepayCancelledGA,
  trackRepayExecuteGA,
  trackRepayFailedGA,
  trackRepaySuccessGA,
  trackSellCancelledGA,
  trackSellExecuteGA,
  trackSellFailedGA,
  trackSellSuccessGA,
} from '@/app/analytics'
import { Button, Conditional, SlippageModal, TokenImage } from '@/app/components'
import { Modal } from '@/app/components/Modal'
import { clsxm } from '@/app/helpers/clsxm'
import { type ActionType, sendTransaction } from '@/app/helpers/sendTransaction'
import { useBalance } from '@/app/hooks/useBalance'
import { useNetworkStatus } from '@/app/hooks/useNetworkStatus'
import { CloseModalButton, CloseModalTabControl } from '@/app/pages/positions/components'
import { calculatePositionMetrics, getCloseModalButtonMode } from '@/app/pages/positions/utils'
import { useLavarage } from '@/app/providers/LavarageProvider.js'
import { useAlertsStore, usePreferencesStore } from '@/app/stores'
import { IconBirdeye, IconSolscan } from '@/assets'
import { JUPITER_PLATFORM_FEE_BPS_HAS_LAVA_NFT, JUPITER_PLATFORM_FEE_BPS_NO_LAVA_NFT, PROFIT_FEE } from '@/config'
import { formatSol } from '@/utils/formatters'
import { web3 } from '@coral-xyz/anchor'
import { type Position } from '@lavarage/entities'
import { getAccount, getAssociatedTokenAddressSync } from '@solana/spl-token'
import { useWallet } from '@solana/wallet-adapter-react'
import { PublicKey } from '@solana/web3.js'
import { useQueryClient } from '@tanstack/react-query'
import { forwardRef, useState } from 'react'
import { useShallow } from 'zustand/react/shallow'
import type { TokenInfo } from '../../../../services/types.js'
import { Hint } from '../../../components/Hint.js'
import { LabelledValue } from '../../../components/LabelledValue.js'
import { fetchSwapInfo, useConfirmedSell } from '../../../hooks/useConfirmedSell.js'
import { useNftAccess } from '../../../hooks/useNftAccess.js'

type Props = {
  position: Position
  prices?: Record<string, { price: number }>
  timezone: string
  tokens?: TokenInfo[]
  onExit?: () => void
}
export enum CloseModalTabs {
  SELL = 'Sell',
  REPAY = 'Repay',
}

export const CloseModal = forwardRef<HTMLDivElement, Props>(({
  position,
  prices,
  timezone,
  tokens,
  onExit,
}, ref) => {
  const [addAlert, removeAlert, loading, setLoading, setConfirming, confirming] = useAlertsStore(useShallow(state => [state.addAlert, state.removeAlert, state.loading, state.setLoading, state.setConfirming, state.confirming]))
  const { borrowedAmount, collateralSize, durationDays, interestAccrued } = position
  // const { baseCurrency } = pool
  // const collateralPrice = baseCurrency ? prices?.[baseCurrency.address]?.price : undefined
  // const solPrice = prices?.[SOL_ADDRESS]?.price
  const [isConfirmedPosition, setIsConfirmedPosition] = useState(false)
  const { pool: { baseCurrency, quoteCurrency } } = position
  if (!baseCurrency || !quoteCurrency) return null
  const baseSymbol = baseCurrency.symbol
  const quoteSymbol = position.pool.quoteCurrency?.symbol as string
  const lavarages = useLavarage()
  const lavarage = quoteSymbol === 'SOL' ? lavarages[0] : lavarages[1]
  const wallet = useWallet()
  const slippage = usePreferencesStore(state => state.slippage)
  const queryClient = useQueryClient()
  const token = baseCurrency
  // const token = tokens?.find(({ address }) => address === baseCurrency?.address)
  const collateralPrice = prices?.[baseCurrency.address]?.price
  const quotePriceInUSD = prices?.[quoteCurrency?.address]?.price
  const { hasLavaRockNFT } = useNftAccess()
  const confirmedSellInSol = useConfirmedSell(position, hasLavaRockNFT, Number(slippage))
  const {
    pnl,
    currentLTV,
    averageDailyInterest,
    sellFromPosition,
  } = calculatePositionMetrics(position, quotePriceInUSD, collateralPrice, timezone, confirmedSellInSol)

  const isButtonDisabled = loading || confirming || isConfirmedPosition
  const [currentTab, setCurrentTab] = useState<CloseModalTabs>(CloseModalTabs.SELL)
  const totalOwing = borrowedAmount.plus(interestAccrued).toNumber()
  const jupiterPlatformFeeBps = hasLavaRockNFT ? JUPITER_PLATFORM_FEE_BPS_HAS_LAVA_NFT : JUPITER_PLATFORM_FEE_BPS_NO_LAVA_NFT
  const jupiterPlatformFee = Number(sellFromPosition) * jupiterPlatformFeeBps / 10_000

  const sellPosition = async () => {
    try {
      setLoading(true)
      setConfirming(true)
      trackSellExecuteGA()
      const positionATA = getAssociatedTokenAddressSync(new web3.PublicKey(baseCurrency?.address), new web3.PublicKey(position.publicKey), true)
      const balance = await getAccount(lavarage.program.provider.connection, positionATA)
      const actionType: ActionType = 'sell'
      const info = await fetchSwapInfo(lavarage, position, hasLavaRockNFT, Number(slippage))

      const tx = await lavarage.sellPosition(position.pool.publicKey, balance.amount.toString(), new PublicKey(position.seed), info)
      const txDetailsForSentry = {
        type: actionType,
        walletAddress: wallet.publicKey?.toBase58(),
        walletAdapter: wallet.wallet?.adapter.name,
        tokenSymbol: position.pool.baseCurrency?.symbol,
        tokenAddress: position.pool.baseCurrency?.address,
        amount: parseFloat(balance.amount.toString()),
        position,
        slippage: parseFloat(slippage),
      }

      const res = await sendTransaction(
        tx,
        addAlert,
        removeAlert,
        setConfirming,
        lavarage.program.provider,
        wallet,
        txDetailsForSentry,
        actionType,
        queryClient,
      )
      if (res) {
        trackSellSuccessGA()
        setIsConfirmedPosition(true)
      }
    }
    catch (error) {
      if ((error as Error).message.includes('User rejected the request')) {
        trackSellCancelledGA()
      }
      else {
        trackSellFailedGA()
        addAlert({
          reasonMessage: (error as Error).message,
          type: 'error',
        })
      }
      console.error('Error in selling position:', error)
    }
    finally {
      setConfirming(false)
      setLoading(false)
    }
  }

  const repaySol = async () => {
    try {
      setLoading(true)
      setConfirming(true)
      trackRepayExecuteGA()

      const tx = await lavarage.repaySol(position.pool.publicKey, borrowedAmount.toNumber(), new PublicKey(position.seed), Number(position.collateralSize))

      setConfirming(true)
      const txDetailsForSentry = {
        type: 'Repay',
        walletAddress: wallet.publicKey?.toBase58(),
        walletAdapter: wallet.wallet?.adapter.name,
        tokenSymbol: token.symbol,
        tokenAddress: token.address,
        amount: borrowedAmount.toNumber(),
        position,
      }

      const res = await sendTransaction(tx, addAlert, removeAlert, setConfirming, lavarage.program.provider, wallet, txDetailsForSentry, 'repay', queryClient)
      if (res) {
        trackRepaySuccessGA()
        setIsConfirmedPosition(true)
      }
    }
    catch (error) {
      if (!(error as Error).message.includes('User rejected the request')) {
        trackRepayCancelledGA()
      }
      else {
        trackRepayFailedGA()
      }
    }
    finally {
      setConfirming(false)
      setLoading(false)
    }
  }

  // FROZEN PARAMS
  const realPnL = confirmedSellInSol
    ? confirmedSellInSol - position.initialMargin.toNumber() - borrowedAmount.toNumber()
    : Number(pnl)
  const displayedSellFromPosition = confirmedSellInSol ? confirmedSellInSol : sellFromPosition
  const profitFee = realPnL >= 0 ? realPnL * (PROFIT_FEE / 100) : 0
  const realCurrentLTV = confirmedSellInSol ? (borrowedAmount.toNumber() / confirmedSellInSol) * 100 : currentLTV.toNumber()
  const displayedSellFee = profitFee + jupiterPlatformFee
  const displayedRepayFee = profitFee
  const displayedTotal = displayedSellFromPosition - totalOwing - displayedSellFee
  const isOnline = useNetworkStatus()
  const { userBalance } = useBalance()
  const available = userBalance?.[quoteSymbol as keyof typeof userBalance]
  const closeModalButtonMode = getCloseModalButtonMode({
    isOnline,
    currentTab,
    confirming,
    isConfirmedPosition,
    available,
    borrowedAmount: borrowedAmount.toNumber(),
    isRestricted: false,
  })
  return (
    <Modal className='z-40'>
      <div ref={ref} className='bg-vibrant modal-box max-w-[380px] p-[10px] pb-5 text-sm'>
        <div className='mb-[10px] flex grow items-center justify-between py-[8.5px] text-base'>
          <div className='w-[44px]'/>
          <span>Close Position</span>
          <div className='flex'>
            <IconSolscan className='my-auto mr-[10px] cursor-pointer' onClick={() => window.open(`https://solscan.io/account/${position?.publicKey}`, '_blank', 'noopener,noreferrer')}/>
            <IconBirdeye className='my-auto cursor-pointer' onClick={() => window.open(`https://birdeye.so/token/${position?.pool.baseCurrency?.address}?chain=solana`, '_blank', 'noopener,noreferrer')}/>
          </div>
        </div>
        <div className='flex flex-col gap-5'>
          <section className='bg-main flex flex-col rounded-lg bg-opacity-5 px-[10px] py-[15px]'>
            <div className='mb-[10px] flex items-center justify-between text-base'>
              <div className='flex items-center gap-2'>
                <div className='text-center'>
                  <TokenImage altName={baseCurrency?.name ?? ''} logoURI={baseCurrency?.logoURI ?? ''}/>
                </div>
                <span className='text-center'>{`${formatSol(collateralSize.toNumber(), position.pool.baseCurrency?.decimals)} ${baseSymbol?.toLocaleUpperCase()}`}</span>
              </div>
              <div className='flex'>
                <span className='mr-1'>
                  {formatSol(displayedSellFromPosition)}
                  {' '}
                  {quoteSymbol}
                </span>
              </div>
            </div>
            <div className='flex h-[17px] gap-[10px]'>
              <div className='flex flex-1 items-center justify-between'>
                <span className='my-auto opacity-70'>LTV</span>
                <div>
                  <span
                    className={clsxm({
                      'text-purple': realCurrentLTV >= 90,
                      'text-yellow': realCurrentLTV >= 70 && realCurrentLTV < 90,
                      'text-main': realCurrentLTV < 70,
                    })}
                  >
                    {formatSol(realCurrentLTV, 1)}
                    %
                  </span>
                </div>
              </div>
              <div className='flex flex-1 items-center justify-between'>
                <span className='opacity-70'>PnL</span>
                <span
                  className={clsxm({
                    'text-green': realPnL > 0,
                    'text-yellow': realPnL < 0,
                  })}
                >
                  <span>
                    {formatSol(realPnL)}
                    {' '}
                    {quoteSymbol}
                  </span>
                </span>
              </div>
            </div>
          </section>

          <section className='border-main flex flex-col gap-[5px] rounded-lg border border-opacity-10 p-[10px]'>
            <div className='flex justify-center text-xs opacity-40'>LOAN BALANCE</div>
            <div className='flex justify-between'>
              <div className='opacity-70'>Borrowed Amount</div>
              <div>
                {formatSol(borrowedAmount.toNumber())}
                {' '}
                {quoteSymbol}
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='opacity-70'>Duration</div>
              <div>
                {durationDays}
                {' '}
                {durationDays === 1 ? 'day' : 'days'}
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='flex items-center gap-[3px]'>
                <span className='opacity-70'>Daily Interest</span>
              </div>
              <div>
                {formatSol(averageDailyInterest.toNumber())}
                %
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='flex items-center gap-[3px]'>
                <span className='opacity-70'>Interest Accrued</span>
                <Hint text='Interest accrued daily at 00:00 UTC, auto-collected every 7 days if it exceeds 0.1 SOL or equivalent.'/>
              </div>
              <div>
                {formatSol(interestAccrued.toNumber())}
                {' '}
                {quoteSymbol}
                {' '}
                <span
                  className={clsxm({
                    'text-main': position.daysSinceLastInterest === 0,
                    'text-yellow': position.daysSinceLastInterest >= 5 && position.daysSinceLastInterest < 7,
                    'text-purple': position.daysSinceLastInterest >= 7,
                  })}
                >
                  {`${position.daysSinceLastInterest === 0 ? '0' : -position.daysSinceLastInterest}d`}
                </span>
              </div>
            </div>
            <div className='flex justify-between'>
              <div className='flex items-center gap-[3px]'>
                <span className='opacity-70'>Interest Collected</span>
                <Hint text='Interest collected so far (auto-collected every 7 days if it exceeds 0.1 SOL or equivalent).'/>
              </div>
              <div>
                {formatSol(-position.interestCollected.toNumber())}
                {' '}
                {quoteSymbol}
              </div>
            </div>
            <div className='flex justify-between font-bold'>
              <div className='opacity-70'>Total Owing</div>
              <div>
                {formatSol(totalOwing)}
                {' '}
                {quoteSymbol}
              </div>
            </div>
          </section>

          <section className='bg-alt flex flex-col gap-[5px] rounded-lg bg-opacity-10 p-[10px] text-sm'>
            <section
              className={clsxm(
                'padding-y-[8.5px] flex w-full items-center',
                {
                  'justify-between': currentTab === CloseModalTabs.SELL,
                  'justify-center': currentTab === CloseModalTabs.REPAY,
                },
              )}
            >
              {currentTab === CloseModalTabs.SELL && <div className={clsxm('w-[75px]')}/>}
              <div className='flex grow items-center justify-center text-base'>
                <CloseModalTabControl currentTab={currentTab} disabled={isButtonDisabled} setCurrentTab={setCurrentTab}/>
              </div>
              {currentTab === CloseModalTabs.SELL && (
                <div className='text-main w-[75px]'>
                  <SlippageModal disabled={isButtonDisabled}/>
                </div>
              )}
            </section>
            <section className='my-5 flex justify-center text-center'>
              {currentTab === CloseModalTabs.SELL
                ? 'Selling the collateral covers the outstanding loan balance and fee, with the surplus returned to you.'
                : 'Repaying the outstanding loan balance releases the collateral to you.'}
            </section>
            <div className='mb-[10px] flex justify-center text-xs opacity-40'>TRANSACTION SUMMARY</div>

            {currentTab === CloseModalTabs.SELL ? (
              <section className='flex flex-col gap-[5px]'>
                <LabelledValue
                  label='Sale from Position'
                  value={`${formatSol(displayedSellFromPosition)} ${quoteSymbol}`}
                />
                <LabelledValue
                  label='Loan Balance'
                  value={`${formatSol(-totalOwing)} ${quoteSymbol}`}
                />
                <LabelledValue
                  hintInternalComponent={
                    <div className='text-left'>
                      <Conditional if={hasLavaRockNFT}>
                        <span>Fee reduced for </span>
                        <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                        <span> holder!</span>
                        <p className='mb-5'/>
                      </Conditional>
                      <Conditional if={!hasLavaRockNFT}>
                        <span>Own a </span>
                        <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/community/lava-rock-alpha' rel='noreferrer' target='_blank'>Lava Rock</a></span>
                        <span> to reduce your fees!</span>
                        <p className='mb-5'/>
                      </Conditional>
                      <span>More details on </span>
                      <span><a className='underline' href='https://lavarage.gitbook.io/lavarage/platform/fee' rel='noreferrer' target='_blank'>fees</a></span>
                    </div>
                  }
                  label='Fee'
                  value={`${formatSol(-displayedSellFee)} ${quoteSymbol}`}
                />
                <div className='flex justify-between'>
                  <div className='flex'>
                    <span className='text-alt my-auto mr-[3px] font-bold opacity-70'>Transferred to Wallet</span>
                    <Hint text='Actual amount may differ due to slippage and network fees.'/>
                  </div>
                  <div className='text-alt font-bold'>
                    {`${formatSol(displayedTotal)} ${quoteSymbol}`}
                  </div>
                </div>
              </section>
            ) : (
              <section className='flex flex-col gap-[5px]'>
                <LabelledValue
                  label='Loan Balance'
                  value={`${formatSol(-totalOwing)} ${quoteSymbol}`}
                />
                <LabelledValue
                  label='Fee'
                  labelHint='Profit fee applies to positive PnL of this position.'
                  value={`${formatSol(-displayedRepayFee)} ${quoteSymbol}`}
                />
                <div className='text-alt flex justify-between'>
                  <div className='flex'>
                    <span className='my-auto mr-[3px] font-bold opacity-70'>Transferred to Wallet</span>
                  </div>
                  <div className='font-bold'>
                    {formatSol(position.collateralSize.toNumber())}
                    {' '}
                    {baseSymbol?.toLocaleUpperCase()}
                  </div>
                </div>
              </section>
            )}
          </section>
          <section>
            <div className='flex flex-row justify-center gap-[6px]'>
              <Conditional if={!confirming}>
                <Button color='main' disabled={isButtonDisabled} size='s' onClick={onExit}>Cancel</Button>
              </Conditional>
              <CloseModalButton closeModalButtonMode={closeModalButtonMode} repay={repaySol} sell={sellPosition}/>
            </div>
          </section>
        </div>
      </div>
    </Modal>
  )
})
