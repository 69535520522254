import axios from 'axios'
import { useEffect } from 'react'
import { useShallow } from 'zustand/react/shallow'
import { USDC_TOKEN } from '../../config.js'
import { API_HOST } from '../app.config.js'
import { usePreferencesStore } from '../stores/index.js'

export function useCheckVersionAndUpdate() {
  const [version, setVersion, setQuoteToken] = usePreferencesStore(useShallow(s => [
    s.version,
    s.setVersion,
    s.setQuoteToken,
  ]))
  useEffect(() => {
    const checkForUpdate = async () => {
      const { data } = await axios.get('/version', { baseURL: API_HOST })
      const latestVersion = data

      if (version !== latestVersion) {
        console.log('Dapp updated to version:', latestVersion)
        setQuoteToken(USDC_TOKEN)
        setVersion(latestVersion)
        window.location.reload()
      }
    }

    checkForUpdate()
    const interval = setInterval(checkForUpdate, 60000)
    return () => clearInterval(interval)
  }, [])

  return
}
